import React, { useState, useCallback, useEffect, useRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import SignatureCanvas from 'react-signature-canvas'

import exportAsImage from './utils/exportAsImage'
import { useCookies } from 'react-cookie';
import axios from 'axios'

import { LinkSetup, SignIn}  from './Setup'

const socketUrl ='wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';
const restUrl ='https://2zl7yth6pi.execute-api.eu-central-1.amazonaws.com/prod';

const SignatureApp = ({onResponse, posConfig}) => {

  const exportRef = useRef();
  const [messageHistory, setMessageHistory] = useState([]);
  const [message, setMessage] = useState(null)
  const [now, setNow] = useState(new Date())
  const [image, setImage] = useState(false);
  const [kdnr, setKdnr] = useState(0)
  const [s3Key, setS3Key] = useState()

  const [isSigned, setIsSigned] = useState(false)

  const [sigPad, setSigPad] = useState()

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [cookies, setCookie] = useCookies(['secret']);

  const urlParams = new URLSearchParams(window.location.search);

  const { sendMessage, readyState } = useWebSocket(socketUrl, 
    {
      queryParams: {
        station: posConfig.connection.station,
        secret: cookies.secret,
      },
      onMessage: (message) => handleWebsocketEvent(message),
      shouldReconnect: (_closeEvent) => true,
      retryOnError: true,
      onClose: (x) => console.log('closing',x),
      onError: (err) => console.log('error', err),
      onReconnectStop: (err) => console.log('recon stop', err),
    }
  );

  const [task, setTask] = useState();

  const handleWebsocketEvent = (lastMessage) => {
    console.log({lastMessage})
    setMessageHistory((prev) => prev.concat(lastMessage));
    const message = JSON.parse(lastMessage.data)

    if(
      (message.task === 'respSeparateEscrowAccountCheckout' && message.payedOut > 0) ||
      message.task === 'sign' ||
      message.task === 'finish' ||
      message.task === 'finishEscrowPayout' ||
      message.task === 'signRegistrationForm' ||
      message.task === 'finishSignRegistrationForm' ||
      message.task === 'respRequestTwintPayment'
    ){

      setTask(message.task);
      setMessage(message)
      setKdnr(message?.userdata?.kdnr||0)
      setNow(new Date())
      setIsSigned(false)
      sigPad?.clear()
    }
  }

  const handleSignPaper = useCallback(() => {

    const {
      shipments, 
      userdata: {
        kdnr,
        firstname,
        lastname,
      }, 
      paymentMethod, 
      secondPaymentMethod,
      consumedPrepaid,
      signatureName,
      manualScanLog,
      staff
    } = message

    const msg = {task: 'finish', staff, station: posConfig.connection.station, image, shipments, kdnr, paymentMethod, secondPaymentMethod, manualScanLog, consumedPrepaid, signatureName, firstname, lastname, s3Key};
    
    sendMessage(JSON.stringify(msg))
  
  }, [sendMessage, message, image, posConfig.connection.station]);

  const handleSignEscrowPayout = useCallback(() => {


    const msg = {task: 'finishEscrowPayout', station: posConfig.connection.station, image, s3Key};
    
    sendMessage(JSON.stringify(msg))
  
  }, [sendMessage, message, image, posConfig.connection.station]);
  

  const handleSignRegistrationForm = useCallback(() => {

    const msg = {task: 'finishSignRegistrationForm', station: posConfig.connection.station, image, s3Key, input: message};
    
    sendMessage(JSON.stringify(msg))
  
  }, [sendMessage, message, image, posConfig.connection.station]);



  const handleClick = async () => {
    
    const img = await exportAsImage(exportRef.current)
    const { data } = await uploadAbholbeleg(img)
    setIsSubmitting(true);
    if(data.imageUrl){
      setImage(data.imageUrl)
      setS3Key(data.s3Key)
    } 
  }

  const uploadAbholbeleg = async (img) => {

    let endpoint = ''
    
    if (message.task === 'respSeparateEscrowAccountCheckout'){
      endpoint = '/signatureapp/payoutreceipt'
    }

    if (message.task === 'sign'){
      endpoint = '/signatureapp/pickupreceipt';
    }

    if (message.task === 'signRegistrationForm'){
      endpoint = '/signatureapp/registrationform';
    }

    return await axios.post(restUrl + endpoint, {
      image: img,
      kdnr,
    }, {
      headers: {
        authorization: cookies.secret
      }
    })
  
  }

  useEffect(()=>{
    if(image){
      if(task==='sign') handleSignPaper();
      if(task==='respSeparateEscrowAccountCheckout') handleSignEscrowPayout();
      if(task==='signRegistrationForm') handleSignRegistrationForm();
    }else{
      console.log('NO IMAGE')
    }
  },[image])

  useEffect(()=>{
    
  },[])
  
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(()=>{
    if(message?.task === 'finish'){
      setIsSubmitting(false);
    }
    if(message?.task === 'finishEscrowPayout'){
      setIsSubmitting(false);
    }
    if(message?.task === 'finishSignRegistrationForm'){
      setIsSubmitting(false);
    }
  },[message])
  
  


  const twint_reference = urlParams.get('twint_reference');
  useEffect(()=>{
    if(twint_reference){
      const msg = {task: 'twintPaymentResponseAvailable', station: posConfig.connection.station};
      sendMessage(JSON.stringify(msg))
      
    }
  },[twint_reference])
  

  if( ! cookies.secret) return <SignIn />
  if( ! cookies.station) return <LinkSetup />

  
  
  if(task === 'twintPaymentResponseAvailable'){
    const url = new URL(window.location.toString());
    url.search = '';
    window.location = url.toString();
    return;
  }

  if( ! ['sign', 'finish', 'respSeparateEscrowAccountCheckout', 'signRegistrationForm', 'respRequestTwintPayment'].includes(message?.task)) return <><span>{connectionStatus === 'Open' ? <h1>✓</h1> : <>{connectionStatus}</>}</span></>
  if( message?.task === 'finish') return <>{connectionStatus}</>
  
  const submitDisabled = !! ( readyState !== ReadyState.OPEN || isSubmitting || isSigned===false );

  
  if(task === 'respRequestTwintPayment'){
    window.location = message.checkoutUrl;
    return;
  }

  

  


  if(task === 'sign'){
    return (
      <div>

        <div>{connectionStatus !== 'Open' && <>{connectionStatus}</>}</div>
        <ul>
          {false && messageHistory.map((message, idx) => (
            <span key={idx}>{message ? message.data : null}</span>
          ))}
        </ul>

        <button
          onClick={()=> {setIsSubmitting(true); handleClick()}}
          disabled={submitDisabled}
          style={{width:'400px', height: '100px', fontSize: '32px', backgroundColor: submitDisabled ? 'lightgray' : 'lightgreen'}}
        >
          Fertig
        </button>
        
        <div ref={exportRef} style={{width: '400px'}}>
          <h1>Abholbeleg</h1>
          
          <p>Folgende Sendungen werden abgeholt:</p>

          <table>
            <tr>
              <td><b>MPS-ID</b></td>
              <td><b>Preis</b></td>
            </tr>
            { message.shipments.map((e,i)=>{
              return (
                <tr key={i}>
                  <td style={{minWidth: '190px'}}>{e.id}</td>
                  <td style={{textAlign: 'right'}}>{e.preis} €</td>
                </tr>
              )
            }) }

            <tr>
              <td><b>Summe:</b></td>
              <td><b>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(message.shipments.reduce((p,c)=>{
                const preis = c.preis.replace(',','.')*1
                p = p + preis;
                return p;
              },0))}</b></td>
            </tr>
          </table>
          <div style={{outline: 'solid 1px grey', marginTop: '16px', marginBottom: '16px'}}>
          

            <SignatureCanvas onEnd={()=>{setIsSigned(true)}} ref={(ref)=>setSigPad(ref)} penColor='blue' canvasProps={{width: 400, height: 200, className: 'sigCanvas'}} />
          </div>





          {message.paymentMethod === 'guthaben' && <div>Bezahlung mit Guthaben in Höhe von {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(message.consumedPrepaid/100)}.</div>}
          <p>{message.signatureName}</p>
          <p>Bad Säckingen, {now.toLocaleString('de-DE', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'})}</p>
          <p>MyPaketshop, Wallbacherstr. 5, 79713 Bad Säckingen</p>
        </div>
        {isSubmitting && <div style={{padding: '16px', border: 'solid 2px darkgreen', margin: '16px 0', backgroundColor: 'lightgreen'}}>Wird verarbeitet...</div>}
        
      </div>
    );
  }


  if(task === 'respSeparateEscrowAccountCheckout' && message?.payedOut > 0){
    return (
      <div>

        <div>{connectionStatus !== 'Open' && <>{connectionStatus}</>}</div>
        <ul>
          {false && messageHistory.map((message, idx) => (
            <span key={idx}>{message ? message.data : null}</span>
          ))}
        </ul>

        <button
          onClick={()=> {setIsSubmitting(true); handleClick()}}
          disabled={submitDisabled}
          style={{width:'400px', height: '100px', fontSize: '32px', backgroundColor: submitDisabled ? 'lightgray' : 'lightgreen'}}
        >
          Fertig
        </button>
        
        <div ref={exportRef} style={{width: '400px'}}>
          <h1>Quittung für Rückzahlung von Auslagen</h1>
          
          

          <p>Ausgezahlter Betrag: <b>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(message.payedOut/100)}</b></p>
          <p>Beleg-Nr.: {message.receiptId}</p>
          <div style={{outline: 'solid 1px grey', marginTop: '16px', marginBottom: '16px'}}>
          

            <SignatureCanvas onEnd={()=>{setIsSigned(true)}} ref={(ref)=>setSigPad(ref)} penColor='blue' canvasProps={{width: 400, height: 200, className: 'sigCanvas'}} />
          </div>

          <p>{message.signatureName}</p>
          <p>Bad Säckingen, {now.toLocaleString('de-DE', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'})}</p>
          <p>MyPaketshop, Wallbacherstr. 5, 79713 Bad Säckingen</p>
        </div>
        {isSubmitting && <div style={{padding: '16px', border: 'solid 2px darkgreen', margin: '16px 0', backgroundColor: 'lightgreen'}}>Wird verarbeitet...</div>}
        
      </div>
    );
  }


  if(task === 'signRegistrationForm' ){
    const Row = (key) => {

    };
    return (
      <div>

        <div>{connectionStatus !== 'Open' && <>{connectionStatus}</>}</div>
        <ul>
          {false && messageHistory.map((message, idx) => (
            <span key={idx}>{message ? message.data : null}</span>
          ))}
        </ul>

        <button
          onClick={()=> {setIsSubmitting(true); handleClick()}}
          disabled={submitDisabled}
          style={{width:'400px', height: '100px', fontSize: '32px', backgroundColor: submitDisabled ? 'lightgray' : 'lightgreen'}}
        >
          Fertig
        </button>
        
        <div ref={exportRef} style={{width: '400px'}}>
          <h1>Registrierung</h1>
          
          <p>Vorname: {message.passportData.firstname} {message.passportData.firstname !== message.registrationData.firstname && <span style={{textDecoration: 'line-through'}}> ({message.registrationData.firstname})</span>}<br />
          Nachname: {message.passportData.lastname} {message.passportData.lastname !== message.registrationData.lastname && <span style={{textDecoration: 'line-through'}}> ({message.registrationData.lastname})</span>}<br />
          Geburtsdatum: {message.passportData.birthdate} {message.passportData.birthdate !== message.registrationData.birthdate && <span style={{textDecoration: 'line-through'}}> ({message.registrationData.birthdate})</span>}<br />
          Email: {message.email}</p>
          <p>Es gelten unsere AGB mitsamt den Hinweisen zum Vertragsschluss und unsere Datenschutzerklärung.</p>

          <div style={{outline: 'solid 1px grey', marginTop: '16px', marginBottom: '16px'}}>          
            <SignatureCanvas onEnd={()=>{setIsSigned(true)}} ref={(ref)=>setSigPad(ref)} penColor='blue' canvasProps={{width: 400, height: 200, className: 'sigCanvas'}} />
          </div>

          <p>{message.passportData.firstname} {message.passportData.lastname}</p>
          <p>Bad Säckingen, {now.toLocaleString('de-DE', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'})}<br />MA {message.staff}</p>
          <p>MyPaketshop, Wallbacherstr. 5, 79713 Bad Säckingen</p>
        </div>
        {isSubmitting && <div style={{padding: '16px', border: 'solid 2px darkgreen', margin: '16px 0', backgroundColor: 'lightgreen'}}>Wird verarbeitet...</div>}
        
      </div>
    );
  }
}

export default SignatureApp

